import Link from '@components/ui/link'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

export default function ShopByBrandsGrid({ setIsOpenShopByBrand }) {
  const { t: translate } = useTranslation('common')
  const router = useRouter()

  const featuredBrands = [
    {
      url: '/brand/kitch-247-disposables',
      name: "Kitch's",
      logo: '/assets/images/brand/Kitch.jpg',
    },
    {
      url: "/brand/hellmann's",
      name: "Hellmann's",
      logo: '/assets/images/brand/Hellmanns.png',
    },
    {
      url: '/brand/campbell',
      name: "Campbell's",
      logo: '/assets/images/brand/Campbellss.png',
    },
    {
      url: "/brand/amy's",
      name: "Amy's",
      logo: '/assets/images/brand/amys.svg',
    },
  ]

  const brands = [
    {
      url: '/brand/colgate',
      name: 'Colgate',
      logo: '/assets/images/brand/colgate.webp',
    },
    {
      url: '/brand/heinz',
      name: 'Heinz',
      logo: '/assets/images/brand/Heinz.jpg',
    },
    {
      url: '/brand/lipton',
      name: 'Lipton',
      logo: '/assets/images/brand/Lipton.png',
    },
    {
      url: '/brand/montague',
      name: "Amy's",
      logo: '/assets/images/brand/Montague.png',
    },
    {
      url: '/brand/tazo',
      name: 'Tazo',
      logo: '/assets/images/brand/Tazo.png',
    },
    {
      url: '/brand/zevia',
      name: 'Zevia',
      logo: '/assets/images/brand/zevia.png',
    },
    {
      url: '/brand/southbend',
      name: 'Southbend',
      logo: '/assets/images/brand/southbend_.png',
    },
    {
      url: '/brand/cleveland',
      name: 'Cleveland',
      logo: '/assets/images/brand/Cleveland.png',
    },
    {
      url: '/brand/abbys',
      name: 'Abbys',
      logo: '/assets/images/brand/abbys.png',
    },
    {
      url: '/brand/austin',
      name: 'Austin',
      logo: '/assets/images/brand/Austin.png',
    },
    {
      url: '/brand/clamato',
      name: 'Clamato',
      logo: '/assets/images/brand/clamato.png',
    },
    {
      url: '/brand/coffee-house',
      name: 'Coffee House',
      logo: '/assets/images/brand/coffee-hpuse.png',
    },
    {
      url: '/brand/jeff-garden',
      name: 'Jeff-garden',
      logo: '/assets/images/brand/jeff-garden.png',
    },
    {
      url: '/brand/kinnik',
      name: 'Kinnik',
      logo: '/assets/images/brand/kinnik.png',
    },
    {
      url: '/brand/luxor',
      name: 'Luxor',
      logo: '/assets/images/brand/luxor.png',
    },
    {
      url: '/brand/maple',
      name: 'Maple',
      logo: '/assets/images/brand/maple.png',
    },
    {
      url: '/brand/miso-cup',
      name: 'Miso Cup',
      logo: '/assets/images/brand/miso-cup.png',
    },
    {
      url: '/brand/pacific-gold',
      name: 'Pacific Gold',
      logo: '/assets/images/brand/pacific-gold.png',
    },
    {
      url: '/brand/rise',
      name: 'Rise',
      logo: '/assets/images/brand/rise.png',
    },
    {
      url: '/brand/ritz',
      name: 'Ritz',
      logo: '/assets/images/brand/ritz.png',
    },
    {
      url: '/brand/rmc',
      name: 'Rmc',
      logo: '/assets/images/brand/rmc.png',
    },
    {
      url: '/brand/shimmer',
      name: 'Shimmer',
      logo: '/assets/images/brand/shimmer.png',
    },
    {
      url: '/brand/smuggle',
      name: 'Smuggle',
      logo: '/assets/images/brand/smuggle.png',
    },
    {
      url: '/brand/solo',
      name: 'Solo',
      logo: '/assets/images/brand/solo.png',
    },
    {
      url: '/brand/tiger-milk',
      name: 'Tiger Milk',
      logo: '/assets/images/brand/tiger-milk.png',
    },
    {
      url: '/brand/true-goodness',
      name: 'True Goodness',
      logo: '/assets/images/brand/true-goodnes.png',
    },
    {
      url: '/brand/verde',
      name: 'Verde',
      logo: '/assets/images/brand/verde.png',
    },
  ]

  return (
    <div className="!relative search-results w-64 bg-white border border-white shadow-lg rounded shopByDepart h-[58%] overflow-auto !top-0 z-10">
      {/* Featured Brands Section */}
      <div className="p-5 overflow-auto">
        {/* Left side with featured brands */}
        <div className="mb-5">
          <h3 className="text-xl font-medium text-brand">
            {translate('text-featured-brands', { ns: 'common' })}
          </h3>
        </div>
        <div className="w-full px-0 mx-auto">
          <div className="flex flex-wrap mx-0">
            <div className="flex-[0_0_30%] max-width-[30%]">
              <ul className="w-full flex flex-col items-center justify-center text-center">
                {featuredBrands?.map((manufacturer, index) => (
                  <Link
                    href={'#'}
                    onClick={() => {
                      router.replace(manufacturer.url, undefined, {
                        shallow: false,
                      })
                      window.location.href = manufacturer.url
                      setIsOpenShopByBrand(false)
                    }}
                    key={`brand-${index}`}
                    className="w-full flex flex-col items-center justify-center text-center"
                  >
                    <li
                      key={index}
                      className="w-full h-[106px] bg-white mb-4 shadow-dropDown flex pl-[70px] shopByBrand items-center"
                    >
                      <Image
                        src={manufacturer.logo}
                        alt={manufacturer.name}
                        width={118}
                        height={59}
                        className="h-[59px] w-[118px]"
                      />
                      <span className="text-grey-900 text-lg font-medium pl-[20px]">
                        {manufacturer.name}
                      </span>
                    </li>
                  </Link>
                ))}
              </ul>
            </div>
            <div className="flex-[0_0_70%] max-width-[70%]">
              <ul className="flex flex-wrap w-full gap-2 pl-7">
                {brands?.map((brand, index) => (
                  <Link
                    href={'#'}
                    key={index}
                    onClick={() => {
                      router.replace(brand.url, undefined, { shallow: false })
                      window.location.href = brand.url
                      setIsOpenShopByBrand(false)
                    }}
                  >
                    <li className="w-[100px] h-[106px] bg-white mb-4 shadow-[0_6px_10px_5px_rgba(0,0,0,0.1)] flex justify-around items-center">
                      <Image
                        src={brand.logo}
                        alt={brand.name}
                        width={80}
                        height={44}
                        className="h-11 w-20"
                      />
                    </li>
                  </Link>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
